import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import Header from '../components/HeaderDynamicPages/Header';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { WebformConfirmationContainer, WebformConfirmationMessage, WebformConfirmationTitle } from '../styles/Common';
import webformTranslations from '../JSON/StaticSitesTranslations/webformTranslations.json';
import { redirectToMainPage } from '../utils/redirectToMainPage';
import { GLOBAL } from '../constants/languageCode';

const WebformConfirmation = ({ location }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!location?.state) {
            redirectToMainPage(GLOBAL);
        }
    }, [location]);

    if (!location?.state) {
        return '';
    }

    return (
        <Layout title={location?.state?.title} languageVersionsUrl={webformTranslations}>
            <Header item={'Webform confirmation'} />
            <WebformConfirmationContainer>
                <WebformConfirmationTitle>{location?.state?.title}</WebformConfirmationTitle>
                {location?.state?.title && (
                    <WebformConfirmationMessage
                        dangerouslySetInnerHTML={{
                            __html: location?.state?.message ?? t('webformConfirmationMessage'),
                        }}
                    ></WebformConfirmationMessage>
                )}
            </WebformConfirmationContainer>
        </Layout>
    );
};

export default WebformConfirmation;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
